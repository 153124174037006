import React from "react"
import Grid from "@material-ui/core/Grid"
import { Link } from 'gatsby';
import { graphql } from "gatsby"
import serviceStyles from "./service.module.scss"
import SEO from "../../components/seo"
import marketing from "../../images/marketing.jpg"

export default function Marketing({ data }) {
  return (
    <>
      <SEO
        title="Marketing"
        image={`https://www.ppdc.mx${marketing}`}
        url="/services/marketing"
        description="Somos apasionados del mundo digital y la publicidad."
      />
      <Grid container className={serviceStyles.serviceHero}>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          className={serviceStyles.serviceHeroTitleContainer}
        >
          <h1 className={serviceStyles.serviceHeroTitle}>Marketing</h1>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={8}
          className={
            serviceStyles.serviceHeroImageDescriptionContainerMarketing
          }
        >
          <div className={serviceStyles.serviceHeroDescriptionContainer}>
            <h1 className={serviceStyles.serviceHeroTitleTwo}>Marketing</h1>
            <p className={serviceStyles.serviceHeroDescription}>
              Somos apasionados del mundo digital y la publicidad.
              <br />
              Juntamos las mejores prácticas estadísticas y la metodología más
              efectiva de creatividad para crear estrategias únicas. Inspiramos
              mediante contenido y lo medimos para tomar las mejores decisiones
              para tu negocio.
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid container className={serviceStyles.gridContainer}>
        {data.allAirtable.edges.map(edge => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className={serviceStyles.serviceContainer}
              id={edge.node.id}
            >
              <img
                src={
                  edge.node.data.Image
                    ? edge.node.data.Image
                    : edge.node.data.ImageFile.map(image => {
                        return image.url
                      })
                }
                alt={`Imagen de ${edge.node.data.Title}`}
                className={serviceStyles.serviceImage}
              />
              <div className={serviceStyles.serviceTextArea}>
                <h2 className={serviceStyles.serviceTitle}>
                  {edge.node.data.Title}
                </h2>
                {/* <p className={serviceStyles.serviceDescription}>{edge.node.data.Description}</p> */}
                <Link title="contact" paintDrip hex="#DAD4CC" to="/contact">
                  <button className={serviceStyles.ctaButton}>Saber Más</button>
                </Link>
              </div>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}

export const data = graphql`
  query airtableMarketing {
    allAirtable(
      sort: { fields: data___Order }
      filter: { data: { Category: { eq: "Marketing" } } }
    ) {
      edges {
        node {
          id
          data {
            Description
            Category
            Title
            Image
            ImageFile {
              url
            }
          }
        }
      }
    }
  }
`
